import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontSize: 13,
  },
  palette: {
    primary: {
      main: "#1a4e75",
    },
    secondary: {
      main: "#AA0000",
    },
    label: {
      disabledBackground: "#AA0000",
      disabled: "#AA0000",
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#18202c",
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
      },
    },
  },
});

export default theme;
